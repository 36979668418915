import React from 'react';
import SectionHeading from '../Common/SectionHeading';
import Image from 'next/image';
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";
import Link from 'next/link';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8"
    }
}));

const IndicationAnalysis = ({title=null}) => {
  return (
    <>
        <div className='w-11/12 xl:max-w-screen max-w-screen-2xl flex flex-col justify-center mx-auto lg:my-10 my-6'>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="">
                    {(title) &&
                        <SectionHeading title={title}  customClass="text-left"/>
                    }
                    <p>PharmDelve aims to provide professionals worldwide with the best research insights and analysis to help them improve their market position.</p>
                    <button className='btn'><Link href="https://pharmdelve.delveinsight.com/register" style={{color: 'white'}}>Learn More </Link></button>
                </div>
                <div className='relative w-11/12 xl:max-w-screen max-w-screen-2xl h-auto'>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                        <div className='text-center'>
                            <Image 
                                priority={false} 
                                src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/web/Geographies-dark.png'} 
                                width={50} 
                                height={50} 
                                className='mx-auto' alt="Geographies-dark"/>
                            <h4 className='text-2xl'>20+</h4>
                            <p className='mt-0'>Geographies</p>
                        </div>
                        <div className='text-center'>
                            <Image 
                                priority={false} 
                                src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/web/Categories-dark.png'} 
                                width={50} 
                                height={50} 
                                className='mx-auto' alt="Categories-dark"/>
                            <h4 className='text-2xl'>4+</h4>
                            <p className='mt-0'>Categories</p>
                        </div>
                        <div className='text-center'>
                            <Image 
                                priority={false} 
                                src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/web/Therapies-dark.png'} 
                                width={50} 
                                height={50} 
                                className='mx-auto' 
                                alt="Therapies-dark"
                            />
                            <h4 className='text-2xl'>27+</h4>
                            <p className='mt-0'>Therapies</p>
                        </div>
                        <div className='text-center'>
                            <Image 
                                priority={false} 
                                src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/web/Clients-dark1.png'} 
                                width={50} 
                                height={50} 
                                className='mx-auto' 
                                alt="Clients-dark1"
                            />
                            <h4 className='text-2xl'>1000+</h4>
                            <p className='mt-0'>Clients</p>
                        </div>
                    </div>
                    <p className='font-medium m-0 text-lg'>1200+ Reports and Increasing </p>
                        <Box sx={{ flexGrow: 1 }}>
                            <Box sx={{ position: "relative" }}></Box>
                            <BorderLinearProgress variant="determinate" value={80} />
                        </Box>
                    <p className='text-xs m-0'>Furni
                    shed with 1200+ Reports, aiming for 1500+ Milestone</p>
                    <b/>
                </div>
            </div>
        </div>
    </>
  )
}
export default IndicationAnalysis;
